document.addEventListener('DOMContentLoaded', function() {

    const clickButton = document.querySelector('.ecocart-post-hero__link a');
    
    if(clickButton) {

        clickButton.addEventListener('click', function() {

            let interval = setInterval(checkInputElementExistence, 100);
            function checkInputElementExistence() {

                const lead_gen_button_id = clickButton.getAttribute("href");
                
                var w = window.innerWidth;
                let parentElement = null;

                if(w > 999){
                    parentElement =  document.querySelector('#' + lead_gen_button_id.substring(1));
                } else {
                    parentElement =  document.querySelector('#' + lead_gen_button_id.substring(1) + '.mobile');
                    
                    if(!parentElement){
                        parentElement = document.querySelector('#' + lead_gen_button_id.substring(1) + '.desktop_mobile');
                    }
                    
                    parentElement.scrollIntoView();
                }

                if(parentElement){
                    
                    const inputElement =  parentElement.querySelector('.hs_firstname input');
                    
                    if (inputElement) {
                        inputElement.focus();
                        clearInterval(interval);
                    }

                }
            }
        });
    }

});